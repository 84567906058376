import React, { ReactElement } from "react";

import Breadcrumb from "components/Breadcrumb";
import Hero from "components/Hero";
import PageContent from "components/PageContent";
import { Wrapper } from "style/components/Page";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import ArticlePageProps from "./ArticlePageProps";

const ArticlePage = ({
    breadcrumb,
    hero,
    blocks,
    theme = "blue",
    contentTheme = "cyan",
    actionTheme = "cyan",
}: ArticlePageProps): ReactElement => (
    <Wrapper id="maincontent">
        <Hero
            key={`Hero`}
            theme={theme}
            contentTheme={contentTheme}
            actionTheme={actionTheme}
            {...hero}
        />
        {breadcrumb?.items && (
            <Breadcrumb items={breadcrumb.items} theme={theme} />
        )}
        <PageContent
            key={`Blocks`}
            areaName="Blocks"
            mainContent={blocks}
            componentSelector={getComponentTypeForContent}
        />
    </Wrapper>
);

export default React.memo(ArticlePage);
